// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

// THEME COLORS
const blackChocolate = '#28190E'
const orangeYellowCrayola = '#C3B5AC'
const xanadu = '#7D8570'
const flame = '#D95D39'
const lavenderWeb = '#FFAE70'
const logogreen = '#92B513'//'#92B513'


export default createVuetify({
  theme: {
    themes: {
      dark: {
        colors: {
          primary: orangeYellowCrayola,
          secondary: logogreen,
          accent: lavenderWeb,
          error: flame,
          background: blackChocolate,
        }
      },
      light: {
        colors: {
          primary: blackChocolate,
          secondary: xanadu,
          accent: logogreen,
          error: flame,
          background: lavenderWeb,
        }
      },
    },
  },
})
