<template>
  <v-card class="bg-primary pa-5" id="name">
    <h1 class="float-right">ERSCHAFFENE WERKE</h1>
  </v-card>
  <v-container class="ma-0 pa-2">
    <div v-for="project in projects" :key="project.name">
      <Product
        :imgHeight="'600px'"
        :product-name="project.name"
        :img-src="project.imgSrc"
      ></Product>
    </div>
  </v-container>
</template>
<script>
import Product from "../components/Product.vue";
export default {
  name: "Products",
  components: {
    Product,
  },
  setup: () => {
    const allProjects = require("../projects.csv");
    let projects = [];
    for (let project of allProjects.filter((p) => p.project.toLowerCase() == "true")) {
      projects.push({
        name: project.name,
        imgSrc: project.imgSrc.split(",").map((i) => project.name + "_" + i),
        description: project.description,
      });
    }
    return { projects };
  },
};
</script>
<style scoped>
#name {
  border-radius: 0;
  font-size: 1rem;
}

@media screen and (min-device-aspect-ratio: 1/1) {
  #name {
    border-radius: 0 0 15px 15px;
  }
}
</style>
