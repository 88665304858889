import { createApp } from 'vue'
import { loadFonts } from './plugins/webfontloader'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import VueSplide from '@splidejs/vue-splide'

// Views
import Home from './views/Home.vue'
import Products from './views/Products.vue'
import About from './views/About.vue'
//import Shop from './views/Shop.vue'
import Contact from './views/Contact.vue'
import Impressum from './views/Impressum.vue'
import Datenschutz from './views/Datenschutz.vue'


// Styles
import './main.css'

loadFonts()

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/products', component: Products },
    { path: '/about', component: About },
    //{ path: '/shop', component: Shop },
    { path: '/contact', component: Contact },
    { path: '/impressum', component: Impressum },
    { path: '/datenschutz', component: Datenschutz },
  ],
})

createApp(App)
  .use(router)
  .use(vuetify)
  .use(VueSplide)
  .mount('#app')
