<template>
  <v-app id="bg">
    <v-app-bar app elevate-on-scroll color="rgb(255, 255, 255, 0.2)">
      <v-row class="text-center text-primary">
        <v-col cols="12">
          <v-btn
            ><router-link style="text-decoration: none; color: inherit" to="/"
              >Home</router-link
            >
          </v-btn>
          <v-btn
            ><router-link style="text-decoration: none; color: inherit" to="/products"
              >Arbeiten</router-link
            ></v-btn
          >
          <v-btn
            ><router-link style="text-decoration: none; color: inherit" to="/contact"
              >Kontakt</router-link
            >
          </v-btn>
          <v-btn
            ><router-link style="text-decoration: none; color: inherit" to="/about"
              >About</router-link
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main class="align-center justify-center d-flex flex-column">
      <!-- <v-switch
          class="float-right pr-2 text-primary"
          v-model="theme"
          inset
          @change="toggleTheme"
          :label="`${getTheme()} theme`"
        ></v-switch> -->
      <v-container class="pa-0 ma-0" id="content">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer padless class="bg-primary" inset cols="12">
      <v-col class="text-center">
        <v-btn
          v-for="social in socials"
          :key="social.icon"
          class="bg-primary"
          elevation="0"
          icon
          fixed
          :href="social.url"
          target="_blank"
        >
          <v-icon size="24px">
            {{ social.icon }}
          </v-icon>
        </v-btn>
        <strong class="pl-3">Schreinerei M²</strong>
        <br />
        <small
          >Email: info@schreinereim2.de - Website by
          <a
            class="text-decoration-none text-white"
            href="https://github.com/djoas"
            target="_blank"
            >djoas</a
          >
          &
          <a
            class="text-decoration-none text-white"
            href="https://github.com/ljo87"
            target="_blank"
            >ljo87</a
          >
          2023 ©
        </small>
        <br />
        <small class="text-accent">
          <router-link style="text-decoration: underline; color: inherit" to="/impressum"
            >Impressum</router-link
          >
          |
          <router-link
            style="text-decoration: underline; color: inherit"
            to="/datenschutz"
            >Datenschutz</router-link
          >
        </small>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    socials: {
      instagram: {
        icon: "mdi-instagram",
        url: "https://www.instagram.com/schreinerei_m_hoch2/",
      },
    },
  }),
};
</script>

<style>
#bg {
  background-image: url("./assets/images/rough_diagonal.webp");
  background-repeat: repeat;
}

#content {
  width: 100vw;
  max-width: none;
  padding: 0;
  margin: 0;
}

@media screen and (min-device-aspect-ratio: 1/1) {
  #content {
    width: 80vw;
  }
}

.radius15 {
  border-radius: 15px !important;
}

#name {
  font-size: 3em;
  font-family: "ManropeLight", Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: "ManropeLight";
  src: url(./assets/fonts/Manrope-Light.ttf) format("truetype");
}
</style>
