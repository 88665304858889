<template>
  <div class="h-100">
    <Splide
      v-if="!isMobile()"
      :options="{ options }"
      :has-track="false"
      aria-label="My Favorite Images"
      id="splide"
    >
      <SplideTrack class="d-flex flex-column">
        <SplideSlide
          v-for="image in images"
          :key="image.alt"
          class="d-flex flex-column images"
        >
          <v-img
            :lazy-src="require('./../assets/images/' + image.src + '_lowres.jpg')"
            transition="true"
            alt="{{image.alt}}"
            cover
            id="sliderimage"
            :src="require('./../assets/images/' + image.src + '_small.jpg')"
          ></v-img>
        </SplideSlide>
      </SplideTrack>
    </Splide>
    <v-img
      v-else
      cover
      class="mr-8"
      id="mobileInitImage"
      :lazy-src="require('./../assets/images/' + images[0].src + '_lowres.jpg')"
      transition="true"
      alt="{{images[0].alt}}"
      :src="require('./../assets/images/' + images[0].src + '_small.jpg')"
    ></v-img>
    <v-card class="bg-primary pa-5 ml-8 my-8" id="title">
      <h1 id="name" class="float-right pl-3 text-accent">schreinerei m²</h1>
    </v-card>
    <div v-if="isMobile()" id="citation" align="center" justify="center">
      Küche <br />
      Wohnzimmer <br />
      Esszimmer <br />
      Schlafzimmer <br />
      Bad <br />
      — <br />

      Massivholzmöbel <br />
      Einbauschränke<br />
      Garderoben <br />
      Windfänge <br />
      — <br />

      Hotellerie <br />
      Objektplanungen für Gastronomie <br />
      Ladenbau <br />
      — <br />

      Industrieklettern <br />
      Seilzugangstechnik
    </div>
    <v-row v-else>
      <v-col cols="2">
        <v-img
          :max-width="100"
          alt="sawSpinner"
          id="sawSpinner"
          :src="require('../assets/images/wheel-saw.png')"
        >
        </v-img>
      </v-col>
      <v-col>
        <div>
          <v-col class="justify-center">
            <v-row align="center" justify="center"
              ><p id="citation" align="center" justify="center">
                <br />
              </p>
            </v-row>
            <v-row align="center" justify="center"
              ><p id="citation" align="center" justify="center">
                Wohnzimmer — Küche — Esszimmer
              </p>
            </v-row>
            <v-row align="center" justify="center"
              ><p id="citation" align="center" justify="center">
                Schlafzimmer — Badezimmerausstattungen
              </p></v-row
            >
            <v-row align="center" justify="center"
              ><p id="citation" align="center" justify="center">
                Massivholzmöbel — Einbauschränke — Garderoben
              </p></v-row
            >
            <v-row align="center" justify="center"
              ><p id="citation" align="center" justify="center">
                Hotellerie — Objektplanungen für Gastronomie — Ladenbau
              </p></v-row
            >
            <v-row align="center" justify="center"
              ><p id="citation" align="center" justify="center">
                Industrieklettern — Seilzugangstechnik
              </p></v-row
            >
          </v-col>
        </div>
      </v-col>
    </v-row>
    <v-card class="bg-secondary pa-5 mr-8 my-8" id="description">
      <h2>Ihre Vorstellungen umzusetzen ist unser größter Wunsch!</h2>
      <p class="font">
        Wir sind ein innovatives Handwerksunternehmen, das sich genau Ihren Wünschen und
        Vorstellungen anpasst! Ob ein speziell gefertigtes Möbelstück, eine Küche oder
        eine komplette Inneneinrichtung. Unsere Schreinerei steht für nachhaltige und
        ressourcenschonende Produkte. Dabei orientieren wir uns bei der Auswahl von allen
        Materialien an zertifizierten Produkten die nachhaltig angebaut und produziert
        wurden.
      </p>
    </v-card>
    <v-row class="pa-5 my-8" id="bg" align="center" justify="center">
      <p id="citation" align="center" justify="center">
        Wir planen, fertigen und montieren für Sie nach Ihren individuellen Wünschen,
        kommen Sie mit Ihren Ideen gerne auf uns zu!
      </p>
    </v-row>
  </div>
</template>
<script>
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";
// import "@splidejs/vue-splide/css/core";
export default {
  name: "Home",
  components: {
    Splide,
    SplideSlide,
    SplideTrack,
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    const images = require("../starting.csv");
    const options = {
      rewind: true,
      autoplay: true,
    };

    return {
      images,
      options,
    };
  },
  data: () => ({
    items: [
      {
        title: "Wohnzimmer — Schlafzimmer — Küche — Esszimmer — Bad",
        value: 1,
      },
      {
        title: "Massivholzmöbel — Einbauschränke — Garderoben — Windfänge",
        value: 3,
      },
      {
        title: "Hotellerie — Objektplanungen für Gastronomie — Ladenbau",
        value: 8,
      },
    ],
  }),
};
</script>

<style scoped>
#splide {
  /* position: absolute; */
  left: 0;
  min-width: 100%;
  max-height: 65vh;
  overflow: clip;
  display: flex;
  flex-direction: column-reverse;
}

#sawSpinner {
  min-width: 200px;
  animation-name: saw;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}
@keyframes saw {
  0% {
    left: 0px;
    top: 0px;
    transform: rotate(0deg);
  }
  100% {
    left: 0px;
    top: 0px;
    transform: rotate(-720deg);
  }
}

.images {
  bottom: 0;
  right: 0;
}

#sliderimage {
  right: 0;

  bottom: 0;
}

#mobileInitImage {
  /* display: flex; */
  height: 50vh;
  border-radius: 0 0 15px 0;
}

#name {
  font-size: 3em;
  font-family: "ManropeLight", Arial, Helvetica, sans-serif;
}

#citation {
  font-size: 1.25em;
  font-family: "ManropeLight", Arial, Helvetica, sans-serif, italic;
}

.font {
  font-size: 1em;
  font-family: "ManropeLight", Arial, Helvetica, sans-serif, italic;
}

#title {
  border-radius: 15px 0px 0px 15px;
}

#listing {
  border-radius: 15px 15px 15px 15px;
  position: relative;
  font-size: 1.5em;
  font-family: "ManropeLight", Arial, Helvetica, sans-serif, italic;
}

#description {
  border-radius: 0px 15px 15px 0px;
}
</style>
