<template>
  <v-card class="bg-accent my-5 radius15">
    <Splide
      v-if="imgSrc.length > 1"
      :options="{ rewind: true }"
      class="splide max-height"
    >
      <SplideSlide v-for="img in imgSrc" :key="img" class="d-flex max-height">
        <v-img
          @click="() => fullscreen(img, $event)"
          :lazy-src="require(`./../assets/images/${img}_lowres.jpg`)"
          transition="true"
          alt="Image of {{productName}}"
          cover
          :src="require(`./../assets/images/${img}_small.jpg`)"
        ></v-img>
        <v-overlay
          v-model="overlay"
          @click="overlay = false"
          class="align-center justify-center"
        >
          <div class="fullscreenImg">
            <v-img
              :lazy-src="require(`./../assets/images/${fsImage}_lowres.jpg`)"
              transition="true"
              alt="Image of {{productName}}"
              :src="require(`./../assets/images/${fsImage}.jpg`)"
            ></v-img>
          </div>
        </v-overlay>
      </SplideSlide>
    </Splide>
    <v-img
      v-else
      :lazy-src="require(`./../assets/images/${imgSrc[0]}_lowres.jpg`)"
      transition="true"
      alt="Image of {{productName}}"
      :src="require(`./../assets/images/${imgSrc[0]}_small.jpg`)"
    ></v-img>
    <div class="pa-5 ma-5 ml-2">
      <h1 class="">{{ productName }}</h1>
      <h4 class="">{{ productDescription }}</h4>
      <div class="d-flex flex-row-reverse align-center" v-if="buyButton">
        <v-form
          ref="form"
          lazy-validation
          class="text-center py-4"
          color="secondary"
          :action="`mailto:dj999@web.de?subject=${subject}&body=${message}`"
          method="POST"
          enctype="text/plain"
          @submit="sendEmail"
        >
          <v-btn class="bg-secondary text-primary ml-3" type="submit" value="Send"
            >Nachricht senden</v-btn
          >
        </v-form>
        <h3 class="text-secondary">{{ productPrice }}</h3>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "Product",
  props: {
    imgSrc: Array,
    productDescription: String,
    productName: String,
    productPrice: String,
    imgHeight: String,
    buyButton: Boolean,
  },
  data: () => ({
    overlay: false,
    zIndex: 0,
    fsImage: null,
    subject: null,
    message: null,
  }),
  mounted() {
    for (let element of document.getElementsByClassName("splide")) {
      element.style.setProperty("--height", this.imgHeight);
    }

    this.subject = "Anfrage für " + this.productName;
    this.message =
      `Hallo Matthias Joas,%0A\
      %0Aich interessiere mich für das Produkt  ` +
      this.productName +
      `  für den Preis von  ` +
      this.productPrice +
      `. %0A\
      %0AMit freundlichen Grüßen%0ADEIN NAME HIER%0A %0A`;
  },
  methods: {
    fullscreen(img) {
      console.log(img);
      this.fsImage = img;
      this.overlay = !this.overlay;
    },

    sendEmail(event) {
      console.log(event);
    },
  },
};
</script>
<style scoped>
.splide {
  --height: 210px;

  display: flex;
  flex-direction: column;
  left: 0;
  min-width: 100%;
  max-height: 80vh;
  height: var(--height);
  overflow: clip;
}

.fullscreenImg {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.max-height {
  max-height: 400px !important;
}

@media screen and (max-device-aspect-ratio: 1/1) {
  .max-height {
    max-height: 250px !important;
  }
}
.nocontain {
  object-fit: scale-down !important;
}
</style>
