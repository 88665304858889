<template>
  <v-container class = "text-center pt-10">
        <h1>DATENSCHUTZ</h1>
    </v-container>
  <v-container>
    <v-card class="pa-5 my-8" id="description" variant="tonal">
      Informationen über die Erhebung personenbezogener Daten auf unserer
      Webseite: Wir möchten Sie darüber informieren, dass beim Besuch unserer
      Webseite personenbezogene Daten erhoben werden. Personenbezogen sind
      Daten, die auf Sie persönlich beziehbar sind (z. B. Name, E-Mail,
      Nutzerverhalten).
    </v-card>
    <v-card class="bg-accent pa-5 my-8" id="description">
      <h2>I. Name und Anschrift des Verantwortlichen</h2>
      <p>
        Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer
        nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger
        datenschutzrechtlicher Bestimmungen ist die:<br /><br />
      </p>
      <p>
        Schreinerei M²<br />
        Herrmann+Joas GbR<br />
        Schlayerstraße 6<br />
        72793 Pfullingen<br />
        E-Mail: info@schreinereim2.de<br />
      </p>
    </v-card>

    <v-card class="bg-accent pa-5 my-8" id="description">
      <h2>II. Allgemeines zur Datenverarbeitung</h2>
      <br />
      <h3>1. Umfang der Verarbeitung personenbezogener Daten</h3>
      <p>
        Wir erheben und verwenden personenbezogene Daten unserer Nutzer
        grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen
        Website sowie unserer Inhalte und Leistungen erforderlich ist. Die
        Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt
        regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in
        solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus
        tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten
        durch gesetzliche Vorschriften gestattet ist.
      </p>
      <br />
      <h3>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
      <p>
        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
        Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a
        EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.<br />
        <br />
        Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
        eines Vertrages, dessen Vertragspartei die betroffene Person ist,
        erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage.
        Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung
        vorvertraglicher Maßnahmen erforderlich sind.<br />
        <br />
        Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
        rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
        unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.<br />
        <br />
        Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder
        einer anderen natürlichen Person eine Verarbeitung personenbezogener
        Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
        Rechtsgrundlage.<br />
        <br />
        Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
        Unternehmens oder eines Dritten erforderlich und überwiegen die
        Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
        erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
        Rechtsgrundlage für die Verarbeitung.
      </p>
      <br />
      <h3>3. Datenlöschung und Speicherdauer</h3>
      <p>
        Die personenbezogenen Daten der betroffenen Person werden gelöscht oder
        gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung
        kann darüber hinaus erfolgen, wenn dies durch den europäischen oder
        nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
        sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
        wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn
        eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft,
        es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der
        Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
      </p>
    </v-card>

    <v-card class="bg-accent pa-5 my-8" id="description">
      <h2>III. Bereitstellung der Website und Erstellung von Logfiles</h2>
      <p>
        Unserer Webseite dient ausschließlich der Bereitstellung von
        Informationen über unser Unternehmen und unsere Angebote. Beim Besuch
        unserer Webseite werden nur die Daten erhoben, die von Ihrem Browser an
        unseren Server übermittelt werden. Diese Daten sind notwendig, damit Sie
        unsere Webseite angezeigt bekommen und auf ihr navigieren können.
        Rechtsgrundlage für die Erhebung der Daten ist Art. 6 Abs. 1 f)
        EU-Datenschutz-Grundverordnung. Im Einzelnen werden folgende Daten beim
        Besuch unserer Webseite erhoben:
      </p>
      <ol class="pa-5" type="a">
        <li>IP-Adresse</li>
        <li>Art des Browsers, sowie dessen Sprache und Version</li>
        <li>Betriebssystem</li>
        <li>Zugriffstatus/http-Statuscode</li>
        <li>Datum und Uhrzeit der Anfrage, sowie die Zeitzone</li>
        <li>Inhalt der Anforderung und Webseite von der sie kommt</li>
        <li>Übertragene Datenmenge</li>
      </ol>
      Die Löschung dieser Daten erfolgt, wenn diese nicht mehr erforderlich
      sind.
      <p>&nbsp;</p>
      <h2>Ihre Rechte</h2>
      <p>
        Sie haben uns gegenüber folgende Rechte, hinsichtlich der bei uns über
        Sie gespeicherten Daten:
      </p>
      <ol class="pa-5" type="a">
        <li>Recht auf Berichtigung und Löschung</li>
        <li>Recht auf Auskunft</li>
        <li>Recht auf Einschränkung der Verarbeitung</li>
        <li>Recht auf Datenübertragbarkeit</li>
        <li>Recht auf Widerspruch gegen die Verarbeitung</li>
      </ol>
      Ihnen steht weiterhin ein Beschwerderecht beim Landesbeauftragten für den
      Datenschutz und die Informationsfreiheit in Bayern zu.
      <p>&nbsp;</p>
      <h2>Cookies</h2>
      <p>
        Zusätzlich zu diesen Daten nutzen wir über unsere Webseite auch Cookies.
        Dies sind kleine Textprogramme, die nach Besuch unserer Webseite auf
        Ihrem Rechner (in Ihrem Browser) gespeichert werden. Sollten Sie unsere
        Webseite danach erneut besuchen, so sendet der von Ihnen genutzte
        Browser die in dem Cookie gespeicherten Informationen an unsere Webseite
        und kann Ihnen so bspw. die Navigation erleichtern, weil
        Voreinstellungen übernommen werden. Cookies sind keine Viren und können
        auch keine Schadsoftware auf dem Computer installieren. Sie sind nur
        kurze Texte, die zwischen Webserver und Browser ausgetauscht werden. Auf
        unserer Webseite werden folgende Arten von Cookies verwendet:<br />
        <br />
      </p>
      <ol class="pa-5" type="a">
        <li>Transiente Cookies (temporäre Cookies)</li>
        Diese Cookies werden nur für den Zeitraum der Nutzung Ihres Browsers
        gespeichert. Diese speichern eine sogenannte Session-ID, mit welcher
        sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
        zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie
        auf die Webseite zurückkehren. Sobald Sie den Browser schließen, werden
        auch diese Cookies automatisch gelöscht.
        <br />
        <br />
        <li>Persistente Cookies (zeitlich beschränkte Cookies)</li>
        Diese Cookies unterscheiden sich von den Transienten Cookies lediglich
        dadurch, dass sie beim Schließen des Browsers nicht automatisch gelöscht
        werden, sondern erst nach einer voreingestellten Zeit. Sie können diese
        Cookies jedoch jederzeit über die Einstellungen Ihres Browsers löschen.
        Grundsätzlich können Sie die Einstellungen Ihres Browsers so
        konfigurieren, dass Cookies gar nicht oder nur eingeschränkt von diesem
        angenommen und gespeichert werden. Wenn Sie von dieser Möglichkeit
        Gebrauch machen, kann es jedoch zu Einschränkungen bei der Nutzbarkeit
        unserer Webseite kommen.
      </ol>
    </v-card>

    <v-card class="bg-accent pa-5 my-8" id="description">
      <h2>IV. Kontaktformular und E-Mail-Kontakt</h2>
      <br />
      <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
      <p>
        Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für
        die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer
        diese Möglichkeit wahr, so werden die in der Eingabemaske eingegeben
        Daten an uns übermittelt und gespeichert. Diese Daten sind:
      </p>
      <ol class="pa-5" type="a">
        <li>Name, Telefon</li>
        <li>E-Mail-Adresse</li>
        <li>Betreff</li>
        <li>Angefragtes Produkt</li>
        <li>Nachricht/Kommentar/Frage zur Bestellung</li>
      </ol>
      Zum Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten
      gespeichert:
      <ol class="pa-5" type="a">
        <li>IP-Adresse</li>
        <li>Art des Browsers, sowie dessen Sprache und Version</li>
        <li>Betriebssystem</li>
        <li>Zugriffstatus/http-Statuscode</li>
        <li>Datum und Uhrzeit der Anfrage, sowie die Zeitzone</li>
        <li>Inhalt der Anforderung und Webseite von der sie kommt</li>
        <li>Übertragene Datenmenge</li>
      </ol>
      <p>
        Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre
        Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen.
        Alternativ ist eine Kontaktaufnahme über die bereitgestellte
        E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail
        übermittelten personenbezogenen Daten des Nutzers gespeichert.
        <br /><br />Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten
        an Dritte. Die Daten werden ausschließlich für die Verarbeitung der
        Konversation verwendet.
      </p>
      <br />
      <h3>2. Rechtsgrundlage für die Datenverarbeitung</h3>
      <p>
        Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
        Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.<br />
        <br />
        Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
        Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f
        DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so
        ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit.
        b DSGVO.
      </p>
      <br />
      <h3>3. Zweck der Datenverarbeitung</h3>
      <p>
        Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient
        uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer
        Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche
        berechtigte Interesse an der Verarbeitung der Daten.<br />
        <br />
        Die sonstigen während des Absendevorgangs verarbeiteten
        personenbezogenen Daten dienen dazu, einen Missbrauch des
        Kontaktformulars zu verhindern und die Sicherheit unserer
        informationstechnischen Systeme sicherzustellen.
      </p>
      <br />
      <h3>4. Dauer der Speicherung</h3>
      <p>
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
        ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen
        Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per
        E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige
        Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation
        dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene
        Sachverhalt abschließend geklärt ist.<br />
        <br />
        Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen
        Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.
      </p>
      <br />
      <h3>5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
      <p>
        Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
        Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer
        per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner
        personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall
        kann die Konversation nicht fortgeführt werden.<br />
        <br />
        Für den Widerruf der Zustimmung reicht eine formlose Email an die
        Adresse info@schreinereim2.de.<br />
        <br />
        Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme
        gespeichert wurden, werden in diesem Fall gelöscht.
      </p>
    </v-card>

    <v-card class="bg-accent pa-5 my-8" id="description">
      <h2>V. Rechte der betroffenen Person</h2>
      <p>
        Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
        Betroffener i. S. d. DSGVO und es stehen Ihnen folgende Rechte gegenüber
        dem Verantwortlichen zu:
      </p>
      <br />
      <h3>1. Auskunftsrecht</h3>
      <p>
        Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen,
        ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet
        werden. Liegt eine solche Verarbeitung vor, können Sie über folgende
        Informationen Auskunft verlangen:
      </p>
      <ol class="pa-5" type="a">
        <li>
          die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
        </li>
        <li>
          die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
        </li>
        <li>
          die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die
          Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch
          offengelegt werden;
        </li>
        <li>
          die geplante Dauer der Speicherung der Sie betreffenden
          personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
          möglich sind, Kriterien für die Festlegung der Speicherdauer;
        </li>
        <li>
          das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
          betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung
          der Verarbeitung durch den Verantwortlichen oder eines
          Widerspruchsrechts gegen diese Verarbeitung;
        </li>
        <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
        <li>
          alle verfügbaren Informationen über die Herkunft der Daten, wenn die
          personenbezogenen Daten nicht bei der betroffenen Person erhoben
          werden;
        </li>
        <li>
          das Bestehen einer automatisierten Entscheidungsfindung einschließlich
          Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen
          Fällen – aussagekräftige Informationen über die involvierte Logik
          sowie die Tragweite und die angestrebten Auswirkungen einer derartigen
          Verarbeitung für die betroffene Person.
        </li>
      </ol>
      <p>
        Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
        betreffenden personenbezogenen Daten in ein Drittland oder an eine
        internationale Organisation übermittelt werden. In diesem Zusammenhang
        können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO
        im Zusammenhang mit der Übermittlung unterrichtet zu werden.
      </p>
      <br />
      <h3>2. Recht auf Berichtigung</h3>
      <p>
        Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
        gegenüber dem Verantwortlichen, sofern die verarbeiteten
        personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig
        sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
      </p>
      <br />
      <h3>3. Recht auf Einschränkung der Verarbeitung</h3>
      Unter den folgenden Voraussetzungen können Sie die Einschränkung der
      Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
      <ol class="pa-5" type="a">
        <li>
          wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für
          eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die
          Richtigkeit der personenbezogenen Daten zu überprüfen;
        </li>
        <li>
          die Verarbeitung unrechtmäßig ist und Sie die Löschung der
          personenbezogenen Daten ablehnen und stattdessen die Einschränkung der
          Nutzung der personenbezogenen Daten verlangen;
        </li>
        <li>
          der Verantwortliche die personenbezogenen Daten für die Zwecke der
          Verarbeitung nicht länger benötigt, Sie diese jedoch zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
          benötigen, oder
        </li>
        <li>
          wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO
          eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe
          des Verantwortlichen gegenüber Ihren Gründen überwiegen.
        </li>
      </ol>
      <p>
        Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
        eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen –
        nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
        anderen natürlichen oder juristischen Person oder aus Gründen eines
        wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
        verarbeitet werden.<br />
        <br />
        Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen
        eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor
        die Einschränkung aufgehoben wird.
      </p>
      <br />
      <h3>4. Recht auf Löschung</h3>
      <ol class="py-5" type="a">
        <h4>Löschungspflicht</h4>
        <p>
          Sie können von dem Verantwortlichen verlangen, dass die Sie
          betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und
          der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu
          löschen, sofern einer der folgenden Gründe zutrifft:
        </p>
        <ol class="pa-5" type="a">
          <li>
            Die Sie betreffenden personenbezogenen Daten sind für die Zwecke,
            für die sie erhoben oder auf sonstige Weise verarbeitet wurden,
            nicht mehr notwendig.
          </li>
          <li>
            Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem.
            Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es
            fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
          </li>
          <li>
            Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
            Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
            für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO
            Widerspruch gegen die Verarbeitung ein.
          </li>
          <li>
            Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
            verarbeitet.
          </li>
          <li>
            Die Löschung der Sie betreffenden personenbezogenen Daten ist zur
            Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder
            dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
            unterliegt.
          </li>
          <li>
            Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
            angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1
            DSGVO erhoben.
          </li>
        </ol>
        <h4>Information an Dritte</h4>
        <p>
          Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten
          öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren
          Löschung verpflichtet, so trifft er unter Berücksichtigung der
          verfügbaren Technologie und der Implementierungskosten angemessene
          Maßnahmen, auch technischer Art, um für die Datenverarbeitung
          Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber
          zu informieren, dass Sie als betroffene Person von ihnen die Löschung
          aller Links zu diesen personenbezogenen Daten oder von Kopien oder
          Replikationen dieser personenbezogenen Daten verlangt haben.
        </p>
        <br />
        <h4>Ausnahmen</h4>
        <p>
          Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
          erforderlich ist
        </p>
        <ol class="pa-5" type="a">
          <li>
            zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
          </li>
          <li>
            zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung
            nach dem Recht der Union oder der Mitgliedstaaten, dem der
            Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer
            Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
            wurde;
          </li>
          <li>
            aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
            Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3
            DSGVO;
          </li>
          <li>
            für im öffentlichen Interesse liegende Archivzwecke,
            wissenschaftliche oder historische Forschungszwecke oder für
            statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
            Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der
            Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
            beeinträchtigt, oder
          </li>
          <li>
            zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </li>
        </ol>
      </ol>
      <h3>5. Recht auf Unterrichtung</h3>
      <p>
        Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
        Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser
        verpflichtet, allen Empfängern, denen die Sie betreffenden
        personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
        Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es
        sei denn, dies erweist sich als unmöglich oder ist mit einem
        unverhältnismäßigen Aufwand verbunden.<br />
        <br />
        Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese
        Empfänger unterrichtet zu werden.
      </p>
      <br />
      <h3>6. Recht auf Datenübertragbarkeit</h3>
      <p>
        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
        Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie
        das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung
        durch den Verantwortlichen, dem die personenbezogenen Daten
        bereitgestellt wurden, zu übermitteln, sofern
      </p>
      <ol class="pa-5" type="a">
        <li>
          die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a
          DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art.
          6 Abs. 1 lit. b DSGVO beruht und
        </li>
        <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
      </ol>
      <p>
        In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass
        die Sie betreffenden personenbezogenen Daten direkt von einem
        Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
        soweit dies technisch machbar ist. Freiheiten und Rechte anderer
        Personen dürfen hierdurch nicht beeinträchtigt werden.<br />
        <br />
        Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
        personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
        erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
        öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
      </p>
      <br />
      <h3>7. Widerspruchsrecht</h3>
      <p>
        Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
        personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
        DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
        Bestimmungen gestütztes Profiling.<br />
        <br />
        Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen
        Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe
        für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
        Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
        Ausübung oder Verteidigung von Rechtsansprüchen.<br />
        <br />
        Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
        Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
        gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
        Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling,
        soweit es mit solcher Direktwerbung in Verbindung steht.<br />
        <br />
        Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
        werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese
        Zwecke verarbeitet.<br />
        <br />
        Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten
        der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG –
        Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
        denen technische Spezifikationen verwendet werden.
      </p>
      <br />
      <h3>
        8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
      </h3>
      <p>
        Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
        jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
        Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung nicht berührt.
      </p>
      <br />
      <h3>9. Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
      <p>
        Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
        gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
        einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
        Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
        Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
        betreffenden personenbezogenen Daten gegen die DSGVO verstößt. <br />
        Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
        unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der
        Beschwerde einschließlich der Möglichkeit eines gerichtlichen
        Rechtsbehelfs nach Art. 78 DSGVO.
      </p>
      <br />
      <h2>Google Maps</h2>
      <p>
        Unsere Webseite benutzt Google Maps zur Darstellung von Karten und zur
        Erstellung von Anfahrtsplänen. Google Maps wird von Google Inc., 1600
        Amphitheatre Parkway, Mountain View, CA 94043, USA betrieben.<br />
        <br />
        Durch den Besuch auf der Webseite erhält Google Informationen darüber,
        dass Sie die entsprechende Seite unseres Webauftrittes aufgerufen haben.
        Dies erfolgt auch unabhängig davon, ob Sie ein Nutzerkonto bei Google
        haben oder über dieses eingeloggt sind. Für den&nbsp; Fall, dass Sie
        während der Nutzung der Webseite über ein Nutzerkonto von Google
        eingeloggt sind, werden die Daten direkt Ihrem Benutzerkonto zugeordnet.
        Wenn Sie dies nicht wünschen, so müssen Sie sich vor Nutzung des
        Dienstes ausloggen.<br />
        <br />
        Durch die Nutzung von Google Maps erklären Sie sich mit der Erfassung,
        Bearbeitung sowie der Nutzung der automatisch erhobenen sowie der von
        Ihnen eingegeben Daten durch Google und ggf. von Drittanbieter
        einverstanden. Die Nutzungsbedingungen für Google Maps finden Sie unter
        <a
          class="text-primary"
          href="http://www.google.com/intl/de_de/help/terms_maps.html"
          target="_blank"
          >Nutzungsbedingungen für Google Maps</a
        >. Ausführliche Details finden Sie unter google.de
        <a
          class="text-primary"
          href="http://www.google.de/intl/de/privacy/"
          target="_blank"
          >Transparenz und Wahlmöglichkeiten</a
        >
        sowie
        <a
          class="text-primary"
          href="http://www.google.de/intl/de/privacy/privacy-policy.html"
          target="_blank"
          >Datenschutzbestimmungen</a
        >. Das Ihnen zustehende Widerspruchsrecht müssen Sie direkt gegenüber
        Google ausüben. Über Ihre Browsereinstellungen können Sie den Google
        Maps-Dienst deaktivieren (Deaktivierung des JavaScripts im Browser).
        Eine Nutzung ist dann nicht mehr möglich.
      </p>
      <br />
      <h2>Google Web Fonts</h2>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
        einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem
        Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern
        von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass
        über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von
        Google Web Fonts erfolgt im Interesse einer einheitlichen und
        ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn
        Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von
        Ihrem Computer genutzt. Weitere Informationen zu Google Web Fonts finden
        Sie unter
        <a
          class="text-primary"
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          >https://developers.google.com/ fonts/faq</a
        >
        und in der Datenschutzerklärung von Google:&nbsp;<a
          class="text-primary"
          href="https://www.google.com/policies/privacy/"
          target="_blank"
          >https://www.google.com/ policies/privacy/</a
        >.
      </p>
    </v-card>
  </v-container>
</template>
