<template>
  <v-container class="text-center pt-10">
    <h1>IMPRESSUM</h1>
  </v-container>
  <v-container>
    <v-card class="bg-accent pa-5 my-8" id="description">
      <v-row>
        <v-col>
          <h4>Angaben gem. § 5 TMG</h4>
          <p>
            Schreinerei M² Herrmann+Joas GbR
            <br />
            Schlayerstraße 6
            <br />
            72793 Pfullingen
          </p>
        </v-col>
        <v-col>
          <h4>Kontaktaufnahme</h4>
          <p>E-Mail: info@schreinereim2.de</p>
        </v-col>
      </v-row>

      <h3>Umsatzsteuer-ID</h3>
      <p>
        Die UmsatzsteuerID ist aufgrund der Gründungsphase noch nicht vorhanden und wird
        nachgereicht.
        <br />
        Steuernummer: 78037/86308
      </p>
      <br />
      <h3>Haftungsausschluss - Disclaimer:</h3>
      <h4>Haftung für Inhalte</h4>
      <p>
        Alle Inhalte unseres Internetauftritts wurden mit größter Sorgfalt und nach bestem
        Gewissen erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
        können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
        Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
        verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
        oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
        den allgemeinen Gesetzen bleiben hiervon unberührt.
        <br />
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntniserlangung
        einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von den o.g.
        Rechtsverletzungen werden wir diese Inhalte unverzüglich entfernen.
      </p>

      <h4>Haftungsbeschränkung für externe Links</h4>
      <p>
        Unsere Webseite enthält Links auf externe Webseiten Dritter. Auf die Inhalte
        dieser direkt oder indirekt verlinkten Webseiten haben wir keinen Einfluss. Daher
        können wir für die „externen Links“ auch keine Gewähr auf Richtigkeit der Inhalte
        übernehmen. Für die Inhalte der externen Links sind die jeweilige Anbieter oder
        Betreiber (Urheber) der Seiten verantwortlich.
        <br />
        Die externen Links wurden zum Zeitpunkt der Linksetzung auf eventuelle
        Rechtsverstöße überprüft und waren im Zeitpunkt der Linksetzung frei von
        rechtswidrigen Inhalten. Eine ständige inhaltliche Überprüfung der externen Links
        ist ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht möglich. Bei direkten
        oder indirekten Verlinkungen auf die Webseiten Dritter, die außerhalb unseres
        Verantwortungsbereichs liegen, würde eine Haftungsverpflichtung ausschließlich in
        dem Fall nur bestehen, wenn wir von den Inhalten Kenntnis erlangen und es uns
        technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte
        zu verhindern.
        <br />
        Diese Haftungsausschlusserklärung gilt auch innerhalb des eigenen
        Internetauftrittes „Schreinereim2.de“ gesetzten Links und Verweise von
        Fragestellern, Blogeinträgern, Gästen des Diskussionsforums. Für illegale,
        fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der
        Nutzung oder Nichtnutzung solcherart dargestellten Informationen entstehen, haftet
        allein der Diensteanbieter der Seite, auf welche verwiesen wurde, nicht derjenige,
        der über Links auf die jeweilige Veröffentlichung lediglich verweist.
        <br />
        Werden uns Rechtsverletzungen bekannt, werden die externen Links durch uns
        unverzüglich entfernt.
      </p>
      <h4>Urheberrecht</h4>
      <p>
        Die auf unserer Webseite veröffentlichen Inhalte und Werke unterliegen dem
        <a
          class="text-primary"
          href="http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf"
          target="_blank"
          >deutschen Urheberrecht</a
        >. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung des
        geistigen Eigentums in ideeller und materieller Sicht des Urhebers außerhalb der
        Grenzen des Urheberrechtes bedürfen der vorherigen schriftlichen Zustimmung des
        jeweiligen Urhebers i.S.d.
        <a
          class="text-primary"
          href="http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf"
          target="_blank"
          >Urhebergesetzes</a
        >. Downloads und Kopien dieser Seite sind nur für den privaten und nicht
        kommerziellen Gebrauch erlaubt. Sind die Inhalte auf unserer Webseite nicht von
        uns erstellt wurden, sind die Urheberrechte Dritter zu beachten. Die Inhalte
        Dritter werden als solche kenntlich gemacht. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
        Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
        unverzüglich entfernen.
      </p>
    </v-card>
  </v-container>
</template>
