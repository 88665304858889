<template>
  <v-card class="bg-primary pa-5 mb-8" id="name">
    <h1 class="text-accent float-right">KONTAKT</h1>
  </v-card>

  <v-container>
    <v-card class="bg-accent">
      <v-row class="text-justify">
        <v-col id="image">
          <v-img
            :lazy-src="require(`./../assets/images/Logo_ausgeschnitten.png`)"
            transition="true"
            alt="Image of Matthias Joas"
            :src="require(`./../assets/images/Logo_ausgeschnitten.png`)"
          ></v-img>
        </v-col>
        <v-col id="text" class="text-center mb-4 my-sm-auto ma-2 px-2 pb-4">
          <h1>Schreinerei M²</h1>
          <h2>Matthias Joas</h2>
          <h2>Mika Herrmann</h2>
          <br />
          <h2>Schlayerstraße 6</h2>
          <h2>72793 Pfullingen</h2>
          <h2>Email: info@schreinereim2.de</h2>
          <h2>Handy: +49 1577 8204841</h2>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="bg my-5" variant="tonal">
      <h3 class="pa-3 text-center">
        Sende doch direkt eine Nachricht mit deinem Anliegen!
      </h3>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="text-center py-4"
        color="secondary"
        :action="`mailto:info@schreinereim2.de?subject=${subject}&body=${message}`"
        method="POST"
        enctype="text/plain"
        @submit="sendEmail"
      >
        <v-text-field
          v-model="subject"
          label="Betreff"
          required
          color="secondary"
        ></v-text-field>
        <v-textarea
          v-model="message"
          label="Deine Nachricht"
          required
          color="secondary"
        ></v-textarea>

        <v-btn :disabled="!valid" class="ml-auto bg-secondary" type="submit" value="Send">
          Email versenden
        </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    overlay: false,
    valid: true,
    subject: "",
    message: "",
  }),

  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 2000);
    },
  },
  methods: {
    submit(event) {
      console.log(event);
    },
    sendEmail(event) {
      console.log(event);
    },
  },
};
</script>

<style scoped>
#image {
  min-width: 200px;
  max-width: 400px;
}

#name {
  border-radius: 0;
  font-size: 1rem;
}

@media screen and (min-device-aspect-ratio: 1/1) {
  #name {
    border-radius: 0 0 15px 15px;
  }
}

#text {
  border-radius: 15px 15px 15px 15px;
  position: relative;
  font-family: "ManropeLight", Arial, Helvetica, sans-serif, italic;
}
</style>
