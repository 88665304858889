<template>
  <v-card class="bg-primary pa-5 mb-2" id="name">
    <h1 class="float-right text-accent">ABOUT</h1>
  </v-card>

  <div v-if="isMobile()">
    <v-container>
      <v-row>
        <v-img
          id="groupimage"
          :lazy-src="require(`./../assets/images/group_2_lowres.jpg`)"
          transition="true"
          alt="Image of Matthias Joas"
          :src="require(`./../assets/images/group_2.jpg`)"
        ></v-img>
        <v-card id="subtitle_group" class="bg-accent">
          <p class="text-align">
            Nach langjähriger Berufserfahrung und sehr erfolgreichen Weiterbildung zum
            Schreinermeister haben wir uns entschlossen im März 2023 gemeinsam eine
            Schreinerei zu gründen. Das M steht sowohl für die Inhaber Mika Herrmann und
            Matthias Joas als auch für unsere Leidenschaft für Möbel und Massivholz.
            Unsere Produktionsstätte in Pfullingen, in der Region Reutlingen, befindet
            sich im sogenannten Schlayerareal. Für die Abwicklung unserer Aufträge stehen
            uns eine ca. 350m² große Halle, ausgestattet mit einem umfangreichen
            Maschinenpark, sowie ein Büroraum zur Auftragsplanung zur Verfügung. Wir
            übernehmen Planung, Fertigung und Montage Ihrer Ideen und Wünsche bis hin zu
            Gesamtkonzeptionen.
          </p>
        </v-card>
      </v-row>
    </v-container>

    <v-container>
      <v-card class="subtitle-right bg-none" elevation="0">
        <v-col class="pa-0 ma-0 bg-none">
          <v-img
            id="image"
            class=""
            :lazy-src="require(`./../assets/images/Mika_about_lowres.jpg`)"
            transition="true"
            alt="Image of Matthias Joas"
            :src="require(`./../assets/images/Mika_about.jpg`)"
          ></v-img>
        </v-col>
        <v-col class="bg-primary pa-8 pt-8">
          <v-row>
            <v-col class="px-5" id="text">
              <h4 class="pb-1">Warum Schreiner?</h4>
              <p class="float-right pl-3" id="text">
                Das Arbeiten mit Holz hat mir schon im Kindesalter sehr gut gefallen. Der
                Techniklehrer meiner Schule war selbst Schreiner und hat mich somit in
                diese Richtung sehr gut fördern können. Nach der Schule und einigen
                Praktikas war für mich klar: Ich werde Schreiner. Der Job macht mir bis
                heute sehr viel Spaß, weil er Abwechslungsreich ist und man jeden Abend
                sieht was man erschaffen hat.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-5" id="text">
              <h4 class="pb-1">Lieblings Holzart?</h4>
              <p class="float-right pl-3" id="text">
                Eiche und Esche. Persönlich gefallen sie mir am besten, wenn sie astig
                sind oder rustikal, mit einer gebürsteten Oberfläche.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-5" id="text">
              <h4 class="pb-1">Coolstes Projekt?</h4>
              <p class="float-right pl-3" id="text">
                Vor einiger Zeit habe ich von einem Kunden jede Menge alte Eichenbalken
                bekommen. Etwa zur gleichen Zeit habe ich erfahren, dass bei mir im Ort
                eine Bankfiliale abgerissen wird, in der sich noch mehrere große
                Panzer-Glas-Platten befanden. Mir kam schnell die Idee aus den beiden
                Werkstoffen einen Esstisch für meine Wohnung zu bauen. Die Bearbeitung der
                alten Balken stellte sich als besonders anspruchsvoll heraus, da diese
                keinerlei rechte Winkel hatten. Dies erschwerte die maschinelle
                Bearbeitung sehr. Dennoch wurde nach einigen Stunden Arbeit ein Tisch
                draus.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-container>

    <v-container>
      <v-card class="subtitle-left bg-none" elevation="0">
        <v-col class="pa-0 ma-0 bg-none">
          <v-img
            id="image"
            class=""
            :lazy-src="require(`./../assets/images/Matthias_about_lowres.jpg`)"
            transition="true"
            alt="Image of Matthias Joas"
            :src="require(`./../assets/images/Matthias_about.jpg`)"
          ></v-img>
        </v-col>
        <v-col class="bg-accent pa-8 ma-0">
          <v-row>
            <v-col class="px-5" id="text">
              <h4 class="pb-1">Warum Schreiner?</h4>
              <p class="float-right pl-3" id="text">
                Mein Onkel hatte früher eine eigene Schreinerei, in der ich in den Ferien
                öfters mitarbeiten durfte. Als ich mich dann nach dem Abi für einen Beruf
                entscheiden sollte, beschloss ich eine Schreinerlehre anzufangen. Die
                vielfältige Arbeitsweise und die täglichen Herausforderungen motivieren
                mich jeden Tag.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-5" id="text">
              <h4 class="pb-1">Lieblings Holzart?</h4>
              <p class="float-right pl-3" id="text">
                Europäischer Nussbaum. Am besten sehen hier Möbel aus, bei welchen die
                Oberfläche geölt wurde. Ein Beispiel ist das Buffet, welches unter
                Arbeiten zufinden ist.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-5" id="text">
              <h4 class="pb-1">Coolstes Projekt?</h4>
              <p class="float-right pl-3" id="text">
                Ein Kunde wollte eine Kommode für sein Esszimmer aus Eichebalken, welche
                er aus seinem alten Dachstuhl hatte. Die alten Eichebalken mit
                schmiedeeisernen Beschlägen in eine klassische Komode zu integrieren
                stellte ein schönes Projekt dar. Der heutige Schreiner bekommt eher selten
                Aufträge für klassische Möbelstücke wie z. B. Kommoden, weshalb diese
                Projekte umso besonderer sind.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-container>
  </div>

  <div v-else>
    <v-container>
      <v-row>
        <v-col>
          <v-img
            id="groupimage"
            :lazy-src="require(`./../assets/images/group_2_lowres.jpg`)"
            transition="true"
            alt="Image of Matthias Joas"
            :src="require(`./../assets/images/group_2.jpg`)"
          ></v-img>
          <v-card id="subtitle_group" class="bg-accent">
            <p class="text-justify text-align">
              Nach langjähriger Berufserfahrung und sehr erfolgreichen Weiterbildung zum
              Schreinermeister haben wir uns entschlossen im März 2023 gemeinsam eine
              Schreinerei zu gründen. Das M steht sowohl für die Inhaber Mika Herrmann und
              Matthias Joas als auch für unsere Leidenschaft für Möbel und Massivholz.
              Unsere Produktionsstätte in Pfullingen, in der Region Reutlingen, befindet
              sich im sogenannten Schlayerareal. Für die Abwicklung unserer Aufträge
              stehen uns eine ca. 350m² große Halle, ausgestattet mit einem umfangreichen
              Maschinenpark, sowie ein Büroraum zur Auftragsplanung zur Verfügung. Wir
              übernehmen Planung, Fertigung und Montage Ihrer Ideen und Wünsche bis hin zu
              Gesamtkonzeptionen.
            </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-card class="subtitle-right bg-none font" elevation="0">
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0 bg-none" cols="5" align-self="start">
            <v-img
              id="image"
              class=""
              :lazy-src="require(`./../assets/images/Mika_about_lowres.jpg`)"
              transition="true"
              alt="Image of Matthias Joas"
              :src="require(`./../assets/images/Mika_about.jpg`)"
            ></v-img>
          </v-col>
          <v-col class="bg-primary pa-8 pt-8">
            <v-row>
              <v-col class="px-5" id="text">
                <h4 class="pb-1">Warum Schreiner?</h4>
                <p class="float-right pl-3" id="text">
                  Das Arbeiten mit Holz hat mir schon im Kindesalter sehr gut gefallen.
                  Der Techniklehrer meiner Schule war selbst Schreiner und hat mich somit
                  in diese Richtung sehr gut fördern können. Nach der Schule und einigen
                  Praktikas war für mich klar: Ich werde Schreiner. Der Job macht mir bis
                  heute sehr viel Spaß, weil er Abwechslungsreich ist und man jeden Abend
                  sieht was man erschaffen hat.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-5" id="text">
                <h4 class="pb-1">Lieblings Holzart?</h4>
                <p class="float-right pl-3" id="text">
                  Eiche und Esche. Persönlich gefallen sie mir am besten, wenn sie astig
                  sind oder rustikal, mit einer gebürsteten Oberfläche.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-5" id="text">
                <h4 class="pb-1">Coolstes Projekt?</h4>
                <p class="float-right pl-3" id="text">
                  Vor einiger Zeit habe ich von einem Kunden jede Menge alte Eichenbalken
                  bekommen. Etwa zur gleichen Zeit habe ich erfahren, dass bei mir im Ort
                  eine Bankfiliale abgerissen wird, in der sich noch mehrere große
                  Panzer-Glas-Platten befanden. Mir kam schnell die Idee aus den beiden
                  Werkstoffen einen Esstisch für meine Wohnung zu bauen. Die Bearbeitung
                  der alten Balken stellte sich als besonders anspruchsvoll heraus, da
                  diese keinerlei rechte Winkel hatten. Dies erschwerte die maschinelle
                  Bearbeitung sehr. Dennoch wurde nach einigen Stunden Arbeit ein Tisch
                  draus.
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container>
      <v-card class="subtitle-left bg-none font mb-5" elevation="0">
        <v-row class="pa-0 ma-0">
          <v-col class="bg-accent pa-8 ma-0">
            <v-row>
              <v-col class="px-5" id="text">
                <h4 class="pb-1">Warum Schreiner?</h4>
                <p class="float-right pl-3" id="text">
                  Mein Onkel hatte früher eine eigene Schreinerei, in der ich in den
                  Ferien öfters mitarbeiten durfte. Als ich mich dann nach dem Abi für
                  einen Beruf entscheiden sollte, beschloss ich eine Schreinerlehre
                  anzufangen. Die vielfältige Arbeitsweise und die täglichen
                  Herausforderungen motivieren mich jeden Tag.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-5" id="text">
                <h4 class="pb-1">Lieblings Holzart?</h4>
                <p class="float-right pl-3" id="text">
                  Europäischer Nussbaum. Am besten sehen hier Möbel aus, bei welchen die
                  Oberfläche geölt wurde. Ein Beispiel ist das Buffet, welches unter
                  Arbeiten zufinden ist.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-5" id="text">
                <h4 class="pb-1">Coolstes Projekt?</h4>
                <p class="float-right pl-3" id="text">
                  Ein Kunde wollte eine Kommode für sein Esszimmer aus Eichebalken, welche
                  er aus seinem alten Dachstuhl hatte. Die alten Eichebalken mit
                  schmiedeeisernen Beschlägen in eine klassische Komode zu integrieren
                  stellte ein schönes Projekt dar. Der heutige Schreiner bekommt eher
                  selten Aufträge für klassische Möbelstücke wie z. B. Kommoden, weshalb
                  diese Projekte umso besonderer sind.
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pa-0 ma-0 bg-none flex-start" cols="5" align-self="start">
            <v-img
              id="image"
              :lazy-src="require(`./../assets/images/Matthias_about_lowres.jpg`)"
              transition="true"
              alt="Image of Matthias Joas"
              :src="require(`./../assets/images/Matthias_about.jpg`)"
            ></v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "About",
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 2000);
    },
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
#image {
  height: 100%;
  align-items: self-start;
}

.font {
  font-size: 0.8em;
  font-family: "ManropeLight", Arial, Helvetica, sans-serif, italic;
}

#subtitle_group {
  border-radius: 0 0 15px 15px;
  top: -15px;
  padding: 1rem;
  font-size: 1em;
  font-family: "ManropeLight", Arial, Helvetica, sans-serif, italic;
}

.subtitle-left {
  border-radius: 15px 0 0 15px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.bg-none {
  background-color: transparent;
}
.subtitle-right {
  border-radius: 0 15px 15px 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

#name {
  font-size: 1rem;
}

@media screen and (min-device-aspect-ratio: 1/1) {
  #name {
    border-radius: 0 0 15px 15px;
  }
  .person-detail {
    padding: 0 10rem 0 10rem;
  }
  .subtitle-right {
    margin-right: 6rem;
  }

  .subtitle-left {
    margin-left: 6rem;
  }
}

#citation {
  font-size: 1.25em;
  font-family: "ManropeLight", Arial, Helvetica, sans-serif, italic;
}
</style>
